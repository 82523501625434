import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import Layout from "../../components/layout";
import BrandIdentityIcon from "../../icons/brand-identity.svg";
import PersonasIcon from "../../icons/personas.svg";
import EvaluatingIcon from "../../icons/evaluating.svg";
import StepUpIcon from "../../icons/step-up.svg";
import ReimbursementIcon from "../../icons/reimbursement.svg";
import backgroundImage from "../../images/backrounds/design-services-hero.jpg";
import InfoContactUsForm from "../../components/info-contact-us-form";
import {graphql, navigate, useStaticQuery} from "gatsby";

const subscriberLpCalculateUserExperienceRoi = process.env.GATSBY_SUBSCRIBER_LP_CALCULATE_USER_EXPERIENCE_ROI_FORM_TAG;

const Hero = () => (
  <div
    className="hero mt-0"
    style={{
      backgroundImage: `url(${backgroundImage})`,
    }}
  >
    <div className="hero-title bg-primary-transparent flex-md-grow-1 flex-lg-grow-0 mt-0">
      <h1 className="text-white">Show Your Boss The ROI Of Good Web Design</h1>
    </div>
  </div>
);

const Main = ({location}) => {

  const {pdf} = useStaticQuery(graphql`
    {
      pdf: file(name: { eq: "729-Solutions-The_Value_Of_User_Experience_Design" }) {
        name
        extension
        publicURL
      }
    }
  `);

  return (
    <section className="py-md-6">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8 py-5 pr-md-5 py-md-5">
            <h2>
              Frame the Issue, Demonstrate the Value, Define a Solution - See the Results!
            </h2>
            <p>
              Your website, software, or mobile app works for your business 24/7, with no coffee breaks or vacations.
              Not even you are doing that. But it can still be difficult to demonstrate to your boss the value of
              spending time on your products UI/UX design. Before we give you the download, have you tried telling the
              boss these sobering facts:
            </p>
            <div className="d-flex mb-3">
              <BrandIdentityIcon className="icon mr-3 flex-shrink-0"/>
              <ul className="list-unstyled">
                <li className="mb-3 mb-lg-auto">
                  <strong>
                    38% of visitors will navigate away if a website layout is unattractive
                  </strong>
                </li>
                <li>
                  <strong>
                    88% are less likely to return if they have a bad user experience
                  </strong>
                </li>
              </ul>
            </div>
            <p>
              Convincing, right?! If you’re still not getting through to your boss, we want to arm you with our 3 step
              framework to get you both on the same page: a better UI/UX design that lands you more customers and earns
              you the credit you deserve! (We also recommend you ask for a payrise. Glory is nice. Extra pay is nicer.)
            </p>
            <h2>Key Takeaways</h2>
            <div className="d-flex mb-3">
              <ul className="list-unstyled">
                <li className="d-flex align-items-center mb-3">
                  <PersonasIcon className="icon flex-shrink-0 mr-3"/>
                  <span>Why are we here? Frame the issue</span>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <EvaluatingIcon className="icon flex-shrink-0 mr-3"/>
                  <p>Look under the hood… Demonstrate the value</p>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <StepUpIcon className="icon flex-shrink-0 mr-3"/>
                  <p>Cut to the chase: Define a solution</p>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <ReimbursementIcon className="icon flex-shrink-0 mr-3"/>
                  <p>
                    Cash that check! Show how investment in a redesign improves
                    the bottom line
                  </p>
                </li>
              </ul>
            </div>
            <h3>Clients we’ve worked with</h3>
            <div className="row d-flex align-items-center mb-4">
              <div className="col-6 col-md-5 col-lg-4">
                <StaticImage
                  alt="Bundle logo"
                  className="img-fluid"
                  src="../../images/lp/altafoodcraft-logo.png"
                />
              </div>
              <div className="col-6 col-md-5 col-lg-4 flex-lg-grow-0">
                <StaticImage
                  alt="Bundle logo"
                  className="img-fluid"
                  src="../../images/lp/bundle-logo.png"
                />
              </div>
            </div>
            <div className="row d-flex align-items-center mb-4">
              <div className="col-6 col-md-5 col-lg-4">
                <StaticImage
                  alt="Bundle logo"
                  className="img-fluid"
                  src="../../images/lp/sfmoma-logo.png"
                />
              </div>
              <div className="col-6 col-md-5 col-lg-4">
                <StaticImage
                  alt="Bundle logo"
                  className="img-fluid"
                  src="../../images/lp/sundance-logo.png"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 py-5 bg-light">
            <h3 className="text-center">
              GET YOUR COPY OF THE ROI OF GOOD UI/UX DESIGN
            </h3>
            <p className="text-center">
              Great UI/UX design is a superpower. Download our guide to learn the 3 step framework to getting that
              investment.
            </p>
            <div className="bg-light bg-light">
              <InfoContactUsForm
                location={location}
                tags={[subscriberLpCalculateUserExperienceRoi]}
                onSubmitSuccess={() => navigate('/lp/calculate-user-experience-roi-ty')}
                buttonText="GIVE ME THE GUIDE ALREADY"/>
            </div>
            <p className="text-center">
              “We’ll only use your details to get in touch about stuff we think will be useful to you. You can
              unsubscribe at any time. We won’t hate you, but you can’t come to the Christmas Party.”
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

const Index = ({location}) => (
  <Layout>
    <Hero/>
    <Main location={location}/>
  </Layout>
);

export default Index;
